import { lazy } from 'react';

const Board = lazy(() => import('./board/Board'));

const ScrumboardAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'orders',
      element: <Board />,
    },
  ],
};

export default ScrumboardAppConfig;
