import FuseUtils from "@fuse/utils"
import FuseLoading from "@fuse/core/FuseLoading"
import { Navigate } from "react-router-dom"
import settingsConfig from "app/configs/settingsConfig"
import SignInConfig from "../main/sign-in/SignInConfig"
import SignOutConfig from "../main/sign-out/SignOutConfig"
import Error404Page from "../main/404/Error404Page"
import SettingsAppConfig from "../settings-app/SettingsAppConfig"
import OrdersBoardConfig from "../main/orders/OrdersBoardConfig"
import InvoiceConfig from "../main/invoice/InvoiceConfig"
import ActivityConfig from "../main/activity/ActivityConfig"
import ProductsConfig from "../products/ProductsConfig"

const routeConfigs = [
    OrdersBoardConfig,
    InvoiceConfig,
    ActivityConfig,
    SignOutConfig,
    SignInConfig,
    SettingsAppConfig,
    ProductsConfig,
]

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(
        routeConfigs,
        settingsConfig.defaultAuth
    ),
    {
        path: "/",
        element: <Navigate to="/orders" />,
        auth: settingsConfig.defaultAuth,
    },
    {
        path: "loading",
        element: <FuseLoading />,
    },
    {
        path: "404",
        element: <Error404Page />,
    },
    {
        path: "*",
        element: <Navigate to="404" />,
    },
]

export default routes
