import { lazy } from 'react';

const Activity = lazy(() => import('./Activity'));

const ActivityConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'activity',
      children: [
        {
          path: ':activity',
          element: <Activity />,
        },
      ]
    },
  ],
};

export default ActivityConfig;
