import { lazy } from "react"

const ProductsList = lazy(() => import("./list/ProductsList"))
const ProductDetails = lazy(() => import("./details/ProductDetails"))

const ProductsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "products",
            element: <ProductsList />,
        },
        {
            path: "products/:product",
            element: <ProductDetails />,
        },
    ],
}

export default ProductsConfig
