import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CrossIcon from "@mui/icons-material/Clear";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useState, useRef } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    "$clearIndicatorDirty": {
      visibility: "visible"
    }
  },
  clearIndicatorDirty: {
    visibility: "visible"
  },
  clearIndicator: {
    visibility: "hidden"
  }
}));

function NavigationSearch(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");

  const search = async (target) => {
    setSearchText(target.target.value);
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND}/search/activities`, {
      term: target.target.value
    });
  
    if(data && data.URL) {
      navigate(data.URL);
    }
  }

  const clearFilter = () => {
    setSearchText("");
    searchInput.current.focus();
  };

  return (
    <Box
    component="form"
    sx={{
      p: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 200,
      border: '1px solid #cccccc',
      borderRadius: '1.2rem',
      height: '40px'
    }}
    className="mr-16"
  >
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder="Invoice Search"
      onChange={search}
      value={searchText}
      inputRef={searchInput}
    />
    <IconButton
      color="primary"
      sx={{ p: "5px" }}
      onClick={clearFilter}
      className={clsx({
        [classes.clearIndicator]: searchText.length == 0
      })}
    >
      <CrossIcon sx={{ size:8 }} />
    </IconButton>
  </Box>
  );
}

export default NavigationSearch;
