import { ThemeProvider } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Hidden from "@mui/material/Hidden"
import Toolbar from "@mui/material/Toolbar"
import Alert from "@mui/material/Alert"
import clsx from "clsx"
import { memo } from "react"
import { useSelector } from "react-redux"
import { selectUser } from "app/store/userSlice"
import {
    selectFuseCurrentLayoutConfig,
    selectToolbarTheme,
} from "app/store/fuse/settingsSlice"
import { selectFuseNavbar } from "app/store/fuse/navbarSlice"
import { Link } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import SettingsIcon from "@mui/icons-material/Settings"
import { Tooltip } from "@mui/material"
import CategoryIcon from "@mui/icons-material/Category"
import UserMenu from "../../shared-components/UserMenu"
import NavbarToggleButton from "../../shared-components/NavbarToggleButton"
import NavigationSearch from "../../shared-components/NavigationSearch"

function ToolbarLayout1(props) {
    const user = useSelector(selectUser)
    const role = user.data.roleData
    const config = useSelector(selectFuseCurrentLayoutConfig)
    const navbar = useSelector(selectFuseNavbar)
    const toolbarTheme = useSelector(selectToolbarTheme)

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar
                id="fuse-toolbar"
                className={clsx(
                    "flex relative z-20 shadow-md",
                    props.className
                )}
                color="default"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? toolbarTheme.palette.background.paper
                            : toolbarTheme.palette.background.default,
                }}
                position="static"
            >
                <Toolbar className="p-0 min-h-48 md:min-h-64">
                    <div className="flex flex-1 px-16">
                        {config.navbar.display &&
                            config.navbar.position === "left" && (
                                <>
                                    <Hidden lgDown>
                                        {(config.navbar.style === "style-3" ||
                                            config.navbar.style ===
                                                "style-3-dense") && (
                                            <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                                        )}

                                        {config.navbar.style === "style-1" &&
                                            !navbar.open && (
                                                <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                                            )}
                                    </Hidden>

                                    <Hidden lgUp>
                                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                                    </Hidden>
                                </>
                            )}
                        {user.data.roleData.fullAccess ||
                        user.data.roleData.permissions?.users ||
                        user.data.roleData.permissions?.roles ? (
                            <Tooltip title="Admin Settings" arrow>
                                <Link to="/settings" role="button">
                                    <IconButton>
                                        <SettingsIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        ) : (
                            ""
                        )}
                        {user.data.roleData.permissions?.products ? (
                            <Tooltip title="Products" arrow>
                                <Link to="/products" role="button">
                                    <IconButton>
                                        <CategoryIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        ) : (
                            ""
                        )}
                        {process.env.REACT_APP_ENVIRONMENT ===
                            "development" && (
                            <Alert
                                variant="filled"
                                severity="error"
                                className="ml-24"
                                size="small"
                            >
                                Development System with test data only
                            </Alert>
                        )}
                    </div>

                    <div className="flex items-center px-8 h-full overflow-x-auto">
                        {role.fullAccess ||
                        role.permissions?.invoiceQuickSearch ? (
                            <NavigationSearch />
                        ) : (
                            ""
                        )}

                        <UserMenu />
                    </div>

                    {config.navbar.display &&
                        config.navbar.position === "right" && (
                            <>
                                <Hidden lgDown>
                                    {!navbar.open && (
                                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                                    )}
                                </Hidden>

                                <Hidden lgUp>
                                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                                </Hidden>
                            </>
                        )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}

export default memo(ToolbarLayout1)
