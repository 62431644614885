import { lazy } from "react"
import { Navigate } from "react-router-dom"

const SettingsAppPageLayout = lazy(() => import("./SettingsAppPageLayout"))
const UsersApp = lazy(() => import("./users/UsersApp"))
const UserDetails = lazy(() => import("./users/UserDetails"))
const LocationsApp = lazy(() => import("./locations/LocationsApp"))
const ProductAttributesApp = lazy(() =>
    import("./product-attributes/ProductAttributesApp")
)
const RolesApp = lazy(() => import("./roles/RolesApp"))
const RoleDetails = lazy(() => import("./roles/RoleDetails"))

const SettingsAppConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "settings",
            element: <Navigate to="/settings/users" />,
        },
        {
            path: "settings/products",
            element: <Navigate to="/settings/products/attributes" />,
        },
        {
            path: "settings",
            element: <SettingsAppPageLayout />,
            children: [
                {
                    path: "users",
                    element: <UsersApp />,
                },
                {
                    path: "roles",
                    element: <RolesApp />,
                },
                {
                    path: "locations",
                    element: <LocationsApp />,
                },
                {
                    path: "products",
                    children: [
                        {
                            path: "attributes",
                            element: <ProductAttributesApp />,
                        },
                    ],
                },
            ],
        },
        {
            path: "settings/users/:id",
            element: <UserDetails />,
        },
        {
            path: "settings/roles/:id",
            element: <RoleDetails />,
        },
    ],
}

export default SettingsAppConfig
