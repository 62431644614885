import { lazy } from 'react';

const ActivityLookup = lazy(() => import('./ActivityLookup'));

const InvoiceConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'inv',
      children: [
        {
          path: ':inv',
          element: <ActivityLookup />,
        },
      ]
    },
  ],
};

export default InvoiceConfig;
